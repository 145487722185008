import React from 'react';

import loading_gif from '../../assets/gifs/assets_loading.gif';

const loading = (props) => {

    if (props.show_asset_loading === true) {
      return (
        <div className="assets-loading">
          <img className="loading" src={loading_gif} alt=""></img>
          
        </div>
      )
    } else {
      return (
        null
      )
    }
};

export default loading;
