import React from 'react';

import add_asset from "../../assets/images/new_asset_button.png"

const summary = (props) => {

      let input_element = null
      if (props.show_total_invested_input === true) {
        input_element = (
          <input
            id="total-invested"
            defaultValue={props.total_invested}
            onBlur={props.update_total_invested}>
          </input>
        )
      }


      let balance_element = null
      if (props.show_total_invested_span === true) {
        balance_element = (
          <span id="total-invested-span" onClick={props.total_invested_clicked}>{props.total_invested}</span>
        )
      }


    if (props.authenticated === true) {
      return (
        <div className="summary">
          <div className="total section">
            <span className ="label">Portfolio Value</span>
            <span className="value">{props.currency}{props.total_value}</span>
          </div>
          <div className="total-in section">
            <span className ="label">Total Invested</span>
            <span className="unit">{props.currency}</span>
            {input_element}
            {balance_element}
          </div>
          <div className="total-return section">
            <span className ="label">Return</span>
            <span className="value">{props.total_return}%</span>
          </div>
          <div className="annual-return section">
            <span className ="label">Annualized Return</span>
            <span className="value">{props.annual_return}%</span>
          </div>
          <div className="new-asset-button button" onClick={props.new_asset_button_clicked}>
            <img src={add_asset} alt="Smiley face"></img>
            <span className ="label">Asset</span>
          </div>
        </div>
      )
    } else {
      return null
    }
};

export default summary;
