import React from 'react';

import profile_placeholder from '../../assets/images/profile_placeholder.png';
import close_icon from '../../assets/icons/close.png';

const Settings = (props) => {

    let userCurrencyTicker = null
    if (props.user_currency != null) {
      userCurrencyTicker = props.user_currency.currency_ticker
    }

    // create list of all currencies except user;s currency
    const currencyList = props.currency_list.map((currency, index) => {
        return (
          <option key={currency.id}>{currency.currency_ticker}</option>
        )
    })


    if (props.show_settings === true) {
      return (
        <div className="settings-modal">
          <img className="close-icon" src={close_icon} alt="" onClick={props.close_settings_modal}></img>
          <img className="profile-placeholder" alt="" src={profile_placeholder}></img>
          <span className="input-label">Date of first crypto investment</span>
          <input id="investment-start-date" type="date" defaultValue={props.date_of_first_investment} onKeyDown={(e) => e.preventDefault()} required="required"></input>
          <span className="input-label">Currency</span>
          <select id="settings-modal-currency-select">
            <option>{userCurrencyTicker}</option>
            {currencyList}
          </select>
          <div id="settings-modal-save-button" onClick={props.save_settings} >Save</div>
        </div>
      )
    } else {
      return null;
    }

};

export default Settings;
