import React from 'react';

import close_icon from '../../assets/icons/close.png';

const CryptoAsset = (props) => {

    let input_element = null
    if (props.show_input === true) {
      input_element = (
        <input
          className="crypto-balance value"
          type="number"
          onBlur={props.update_balance}
          defaultValue={props.crypto_balance}
          onClick = {props.clear_balance_value}>
        </input>
      )
    }


    let balance_element = null
    if (props.show_crypto_balance === true) {
      balance_element = (
        <span className="crypto-balance-span">{props.crypto_balance}</span>
      )
    }


    return (
      <div className="crypto-asset"
        onMouseEnter={props.show_close_icon}
        onMouseLeave={props.hide_close_icon}>

        {props.show_asset_close_icon === true ?
          <img className="close-icon" src={close_icon} onClick={props.delete_asset} alt=""></img> : null
        }

        <img className="logo"src={props.logo_url} alt="no logo available"></img>
        <span className="asset-name block">{props.name} </span>
        <span className="balance-container">
          {input_element}
          {balance_element}
          <span className="crypto-balance">&nbsp;{props.ticker}</span>
        </span>
        <span className="local-currency-value block"> {props.local_currency}{props.local_currency_value}</span>
      </div>
    )

};

export default CryptoAsset;
