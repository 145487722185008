import React, { Component } from 'react';

import './App.css';

import Loading from './components/loading/loading';
import Summary from './components/summary/summary';
import Navbar from './components/navbar/navbar';
import CryptoAsset from './components/crypto_asset/crypto_asset';
import NewCryptoAsset from './components/new_crypto_asset/new_crypto_asset';
import Settings from './components/settings/settings';
import ModalBackground from './components/modal_background/modal_background';
import Auth from './components/auth/auth';
import Onboarding from './components/onboarding/onboarding';

import './components/loading/loading.css';
import './components/summary/summary.css';
import './components/navbar/navbar.css';
import './components/crypto_asset/crypto_asset.css';
import './components/new_crypto_asset/new_crypto_asset.css';
import './components/settings/settings.css';
import './components/modal_background/modal_background.css';
import './components/auth/auth.css';
import './components/onboarding/onboarding.css';

import sync_loading_gif from "./assets/gifs/sync_loading_gif.gif"
import sync_loading_img from "./assets/images/sync_loading_img.png"

import axios from 'axios';

class App extends Component {

  state = {
      summary: {
        "total_value": 0,
        total_invested: null,
        "total_return": 0.0,
        "annual_return": 0.0,
        local_currency: "",
        days_since_first_investment: 365,
      },
      assetList: [],
      syncImage: sync_loading_img,
      displaySettings: {
        "show_new_asset": false,
        "show_settings": false,
        "show_modal_background": false,
        show_login_error: false,
        show_total_invested_input: false,
        show_total_invested_span: true,
        show_onboarding: false,
        show_asset_loading: false,
      },
      auth: {
        token: null,
        authenticated: false,
        show_sign_in: true,
      },
      development_variables: {
        mode: "production",//"local",
        url_str: "https://api.tokentrack.xyz/",
      },
      currency_data: {
        user_currency: null,
        currency_list: [],
      },
      onboarding: {
        stage: 1
      }
  }

  syncButtonHandler = () => {

    this.setState({syncImage:sync_loading_gif});

    let url_str = this.state.development_variables.url_str + "api/v1/MarketSync/";
    let request_config = {
      url: url_str,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Token " + this.state.auth.token
      }
    }

    axios.request(request_config)
          .then( response => {

            //this.setState(pre_state);
            this.setState({syncImage:sync_loading_img});
            this.updateAssetValues()
          })
  }

  updateAssetValues = () => {

    let url_str = this.state.development_variables.url_str + "api/v1/CryptoPortfolio/";
    //let url_str = "http://127.0.0.1:8000/api/v1/CryptoPortfolio/";

    let request_config = {
      url: url_str,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Token " + this.state.auth.token
      }
    }

    axios.request(request_config)
          .then( response => {

            const state_copy = this.state;

            state_copy.summary["total_value"] = response.data.total_asset_value;
            state_copy.summary.total_invested = response.data.total_invested;
            state_copy.summary["total_return"] = response.data.total_return;
            state_copy.summary["annual_return"] = response.data.annual_return;
            state_copy.summary["local_currency"] = response.data.local_currency;
            state_copy.summary.days_since_first_investment = response.data.days_since_first_investment;
            state_copy.summary["investment_start_date"] = response.data.investment_start_date;
            state_copy.assetList = response.data.asset_object_list;
            state_copy.auth.authenticated = true;
            state_copy.displaySettings.show_asset_loading = false;

            this.setState(state_copy)
          })
          .catch( error => {
            console.log(error);
            if (error.response.status === 401) {
              const auth = this.state.auth
              auth.authenticated = false
              this.setState({auth:auth})
            }

          })

  }

  updateSingleCryptoBalance = (id, assetIndex, crypto_balance) => {

    let url_str = this.state.development_variables.url_str + "api/v1/updateAssetBalance/";

    let request_config = {
      url: url_str,
      method: "patch",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Token " + this.state.auth["token"]
      },
      data: {
       id: id,
       asset_balance: crypto_balance,
     },


    }

    axios.request(request_config)
          .then( response => {

            let local_currency_value = response.data.local_currency_value

            const assetList = this.state.assetList;
            assetList[assetIndex].local_currency_value = local_currency_value;
            assetList[assetIndex].crypto_balance = crypto_balance;
            this.setState({assetList: assetList})
            this.updateSummaryValues();
          })
  }

  addAssetButtonHandler = () => {

    const displaySettings = this.state.displaySettings;
    displaySettings["show_new_asset"] = true;
    this.setState({displaySettings: displaySettings});

  }

  hoverOverAssetOnHandler = (assetIndex) => {
    const assetList = this.state.assetList
    assetList[assetIndex]["show_close_icon"] = true;
    this.setState({assetList: assetList})
  }

  hoverOverAssetOffHandler = (assetIndex) => {
    const assetList = this.state.assetList;
    assetList[assetIndex]["show_close_icon"] = false;
    this.setState({assetList: assetList});
  }

  deleteAssetButtonHandler = (assetIndex) => {
    const assetList = this.state.assetList;
    const id = assetList[assetIndex]["id"]
    console.log("id = " + id);

    const url_str = this.state.development_variables.url_str + "api/v1/Asset/";

    let request_config = {
      url: url_str,
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Token " + this.state.auth["token"]
      },
      data: {
        "id": id
      },
    }

    axios.request(request_config)
          .then( response => {
            assetList.splice(assetIndex, 1);
            this.setState({assetList: assetList});
            this.updateSummaryValues()
          })
          .catch(function (error) {
            alert(error.response.data)
          })
  }

  assetBalanceClickHandler = (event, assetIndex) => {
      let current_value = event.target.value;

      if (current_value === "0") {
        let state_copy = this.state;
        console.log(state_copy.assetList[assetIndex].crypto_balance);
        state_copy.assetList[assetIndex].crypto_balance = "";
        this.setState(state_copy);
      }
  }

  updateAssetBalance = (event, assetIndex) => {

    console.log("running updateAssetBalance");

    let crypto_balance = event.target.value

    if (crypto_balance.match(/[^$,.\d]/)) {
       alert('Please enter a numeric value');
    } else {
       let id = this.state.assetList[assetIndex]["id"];
       this.updateSingleCryptoBalance(id, assetIndex, crypto_balance);

    }
  }

  newAsstSubmitEventHandler = () => {
    const new_asset_ticker = document.getElementById("new-asset-ticker").value;

    const url_str = this.state.development_variables.url_str + "api/v1/Asset/";

    let request_config = {
      url: url_str,
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Token " + this.state.auth["token"]
      },
      data: {
       ticker: new_asset_ticker,
     },
    }

    axios.request(request_config)
          .then( response => {

            let asset_name = response.data.asset_name
            let ticker = response.data.ticker
            let asset_id = response.data.asset_id
            let logo_url = response.data.logo_url

            const local_currency = this.state.summary.local_currency;
            const assetList = this.state.assetList;
            const new_asset = {
              "name": asset_name,
              "ticker": ticker,
              "logo_url": logo_url,
              "crypto_balance": 0,
              "local_currency_value": 0,
              "local_currency": local_currency,
              "key": asset_id,
              "show_crypto_balance": false,
              "show_input": true,
              "id": asset_id
            }

            assetList.unshift(new_asset)

            const displaySettings = this.state.displaySettings;
            displaySettings["show_new_asset"] = false;

            //this.setState({displaySettings: displaySettings});



            this.setState({assetList: assetList, displaySettings: displaySettings})

          })
          .catch(function (error) {
            // handle error
            alert(error.response.data)
            console.log(error.response);
          })
  }

  closeNewAssetElementHandler = () => {
    const displaySettings = this.state.displaySettings;
    displaySettings["show_new_asset"] = false;
    this.setState({displaySettings: displaySettings});
  }

  updateSummaryValues = () => {
    const assetList = this.state.assetList;
    const total_invested = this.state.summary.total_invested;
    const days_since_first_investment = this.state.summary.days_since_first_investment;

    let total_value = 0
    let i = 0;

    for (i=0; i<assetList.length; i++) {
      total_value = total_value + assetList[i]["local_currency_value"]
    }

    let total_return = 100*(total_value-total_invested)/total_invested;
    total_return = Math.round(10*total_return)/10;
    console.log("total_return = " + total_return);
    let annual_return = Math.round(10*total_return*365/days_since_first_investment)/10;

    const summary = this.state.summary;
    summary.total_value = total_value;
    summary.total_return = total_return;
    summary.annual_return = annual_return
    this.setState({summary: summary})
  }

  totalInvestedChangeHandler = (event) => {

    let updated_value = 0;
    if (event.target.value !== "") {
      updated_value = event.target.value;
    }

    let url_str = this.state.development_variables.url_str + "api/v1/updateTotalInvested/";

    let request_config = {
      url: url_str,
      method: "patch",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Token " + this.state.auth["token"]
      },
      data: {
       "total_invested": updated_value,
     },


    }

    axios.request(request_config)
          .then( response => {
            const state_copy = this.state;
            state_copy.summary.total_invested = updated_value;
            state_copy.displaySettings.show_total_invested_input = false;
            state_copy.displaySettings.show_total_invested_span = true;
            this.setState(state_copy)

            this.updateSummaryValues()

          })
          .catch(function (error) {
            // handle error
            alert("something went wrong")
            console.log(error.response.data);

          })
  }

  settingsChangedHandler = () => {
    let investment_start_date = document.getElementById("investment-start-date").value;
    const currency_ticker = document.getElementById("settings-modal-currency-select").value;

    if (investment_start_date === "") {
      investment_start_date = this.state.summary["investment_start_date"]
    }



    console.log("isd = " + investment_start_date);
    console.log("c_ticker =  " + currency_ticker);
    const data = {
      "investment_start_date": investment_start_date,
      "currency_ticker": currency_ticker,
    }

    let url_str = this.state.development_variables.url_str + "api/v1/settingsView/";

    let request_config = {
      url: url_str,
      method: "patch",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Token " + this.state.auth["token"]
      },
      data: data,
    }

    axios.request(request_config)
          .then( response => {
            const state_copy = this.state
            state_copy.summary.investment_start_date = investment_start_date;

            //close modal
            state_copy.displaySettings["show_settings"] = false
            state_copy.displaySettings["show_modal_background"] = false
            //reset data
            this.updateAssetValues()
            //update state
            this.setState(state_copy)
          })
          .catch(function (error) {
            // handle error
            alert(error.response.data);

          })
  }

  closeModal = () => {
    const displaySettings = this.state.displaySettings;
    displaySettings.show_modal_background = false;
    this.setState({displaySettings: displaySettings})
  }

  showModal = () => {
    const displaySettings = this.state.displaySettings;
    displaySettings.show_modal_background = true;
    this.setState({displaySettings: displaySettings})
  }

  closeSettingsButtonHandler = () => {
    this.closeModal();
    const displaySettings = this.state.displaySettings;
    displaySettings.show_settings = false;
    this.setState({displaySettings: displaySettings});
  }

  showSettingsButtonHandler = () => {
    this.updateCurrencySelect();
    this.showModal();
    const displaySettings = this.state.displaySettings;
    displaySettings.show_settings = true;
    this.setState({displaySettings: displaySettings});
  }

  loginHandler = () => {

    console.log("running loginHandler");

    const email = document.getElementById("login-email").value;
    const password = document.getElementById("login-password").value;

    //some validation:

    // submit:

    let data = {
      "username": email,
      "password": password
    }

    const url_str = this.state.development_variables.url_str + "api/v1/UserLogin/";
    console.log("url_str = " + url_str)

    let request_config = {
      url: url_str,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    }

    axios.request(request_config)
          .then( response => {
            const token = response.data.token
            const state_copy = this.state;
            state_copy.auth.token = token;
            state_copy.auth.authenticated = true;
            state_copy.displaySettings.show_asset_loading = true;
            this.setState(state_copy);
            this.updateAssetValues()
          })
          .catch( error => {
            const displaySettings = this.state.displaySettings;
            displaySettings.show_login_error = true;
            this.setState({displaySettings: displaySettings});
          })

  }

  logoutHander = () => {

    const state_copy = this.state;

    state_copy.summary["total_value"] = 0;
    state_copy.summary.total_invested = 0;
    state_copy.summary["total_return"] = 0;
    state_copy.summary["annual_return"] = 0;
    state_copy.summary.local_currency = "";

    state_copy.auth.token = null;
    state_copy.auth.authenticated = false;

    state_copy.assetList = [];

    state_copy.displaySettings["show_new_asset"] = false;

    this.setState(state_copy);
  }

  switchAuthView = () => {

    const state_copy = this.state;

    if (state_copy.auth.show_sign_in === true) {
      state_copy.auth.show_sign_in = false
    } else {
      state_copy.auth.show_sign_in = true
    }

    state_copy.displaySettings.show_login_error = false
    this.setState(state_copy);
  }

  signUpHandler = () => {
    const email = document.getElementById("sign-up-email").value;
    const password = document.getElementById("sign-up-password").value;
    //const password_2 = document.getElementById("sign-up-password-2").value;

    //some validation:

    // submit:

    let data = {
      "username": email,
      "password": password
    }

    const url_str = this.state.development_variables.url_str + "api/v1/UserSignUp/";

    let request_config = {
      url: url_str,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    }

    axios.request(request_config)
          .then( response => {
            const token = response.data.token

            const state_copy = this.state;
            state_copy.auth.token = token;
            state_copy.auth.authenticated = true;
            state_copy.assetList = []
            this.updateCurrencySelect();
            state_copy.displaySettings.show_modal_background = true;
            state_copy.displaySettings.show_onboarding = true;
            state_copy.displaySettings["show_new_asset"] = true;
            state_copy.displaySettings["show_modal_background"] = true;

            this.setState(state_copy);
            this.updateAssetValues()
          })
          .catch( error => {
            const displaySettings = this.state.displaySettings;
            displaySettings.show_login_error = true;
            this.setState({displaySettings: displaySettings});
          })
  }

  updateCurrencySelect = () => {

    const url_str = this.state.development_variables.url_str + "api/v1/currencies/";

    let request_config = {
      url: url_str,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Token " + this.state.auth.token
      },
    //  data: data,
    }

    axios.request(request_config)
          .then( response => {
            console.log(response.data);
            const user_currency = response.data.user_currency;
            const currency_list = response.data.currency_list;

            const state_copy = this.state
            state_copy.currency_data.user_currency = user_currency
            state_copy.currency_data.currency_list = currency_list
            this.setState(state_copy)
            console.log(this.state);


          })
          .catch( error => {
            console.log("error");
          })
  }

  total_invested_clicked_handler = (event) => {
    let state_copy = this.state;
    state_copy.displaySettings.show_total_invested_input = true;
    state_copy.displaySettings.show_total_invested_span = false;
    this.setState(state_copy);
  }

  closeOnboardingButtonHandler = () => {
    const state_copy = this.state;
    state_copy.displaySettings.show_onboarding = false;
    state_copy.displaySettings.show_modal_background = false;
    this.setState(state_copy);
  }

  onboardingNextButtonHandler = () => {
    const state_copy = this.state;
    let stage = this.state.onboarding.stage;
    stage = stage + 1;
    state_copy.onboarding.stage = stage;
    this.setState(state_copy);
  }

  onboardingBackButtonHandler = () => {
    const state_copy = this.state;
    let stage = this.state.onboarding.stage;
    stage = stage - 1;
    state_copy.onboarding.stage = stage;
    this.setState(state_copy);
  }


  componentDidMount () {

    if (this.state.development_variables.mode === "local") {
      const state_copy = this.state
      state_copy.development_variables.url_str = "http://127.0.0.1:8000/"
      this.setState(state_copy)
    }

    if (this.state.auth.authenticated === true) {
      this.updateAssetValues()
    }


  }

  componentDidUpdate() {

    console.log("componentDidUpdate")

    // hacky way of focusing on show_total_invested_input when rendered
    if (this.state.displaySettings.show_total_invested_input === true) {
      const show_total_invested_input_element = document.getElementById("total-invested");
      show_total_invested_input_element.focus();
    }

  }

  render() {


      const assetList = this.state.assetList.map((asset, index) => {


        if (this.state.auth.authenticated === true) {
          return <CryptoAsset
              name={asset.name}
              ticker={asset.ticker}
              logo_url={asset.logo_url}
              crypto_balance={asset.crypto_balance}
              local_currency = {asset.local_currency}
              local_currency_value={asset.local_currency_value}
              key={asset.id}
              show_asset_close_icon = {asset.show_close_icon}
              show_close_icon = {() => this.hoverOverAssetOnHandler(index)}
              hide_close_icon = {() => this.hoverOverAssetOffHandler(index)}
              update_balance = {(event) => this.updateAssetBalance(event, index)}
              delete_asset = {(event) => this.deleteAssetButtonHandler(index)}
              clear_balance_value = {(event) => this.assetBalanceClickHandler(event, index)}
              show_input = {asset.show_input}
              show_crypto_balance = {asset.show_crypto_balance}

          />
        } else {
          return null
        }


      })


    return (
      <div className="App">
      <ModalBackground
        show_modal_background = {this.state.displaySettings["show_modal_background"]}
      />
      <Loading
        show_asset_loading = {this.state.displaySettings.show_asset_loading}
      />
      <Settings
        show_settings={this.state.displaySettings["show_settings"]}
        date_of_first_investment = {this.state.summary["investment_start_date"]}
        save_settings = {this.settingsChangedHandler}
        close_settings_modal = {this.closeSettingsButtonHandler}
        user_currency = {this.state.currency_data.user_currency}
        currency_list = {this.state.currency_data.currency_list}
      />
      <Onboarding
        close_onboarding_modal = {this.closeOnboardingButtonHandler}
        show_onboarding = {this.state.displaySettings.show_onboarding}
        stage = {this.state.onboarding.stage}
        next_click = {this.onboardingNextButtonHandler}
        back_click = {this.onboardingBackButtonHandler}
      />
      <Navbar
        show_settings = {this.showSettingsButtonHandler}
        authenticated = {this.state.auth.authenticated}
        logout = { (event) => this.logoutHander(event)}
      />
      <Summary
        total_value={this.state.summary.total_value}
        total_invested={this.state.summary.total_invested}
        total_return={this.state.summary.total_return}
        annual_return={this.state.summary.annual_return}
        sync_img = {this.state.syncImage}
        sync_button_clicked = {this.syncButtonHandler}
        new_asset_button_clicked = {this.addAssetButtonHandler}
        update_total_invested = {(event) => this.totalInvestedChangeHandler(event)}
        currency = {this.state.summary.local_currency}
        authenticated = {this.state.auth.authenticated}
        show_total_invested_input = {this.state.displaySettings.show_total_invested_input}
        show_total_invested_span = {this.state.displaySettings.show_total_invested_span}
        total_invested_clicked = {this.total_invested_clicked_handler}
      />
      <Auth
        authenticated = {this.state.auth.authenticated}
        show_sign_in = {this.state.auth.show_sign_in}
        login_submit = {(event) => this.loginHandler(event)}
        sign_up_submit = {this.signUpHandler}
        show_login_error = {this.state.displaySettings.show_login_error}
        switch_auth_view = {this.switchAuthView}
      />
      <NewCryptoAsset
        new_asset_submit = {this.newAsstSubmitEventHandler}
        new_asset_close = {this.closeNewAssetElementHandler}
        show_new_asset = {this.state.displaySettings["show_new_asset"]}
      />
      {assetList}
      </div>
    );
  }
}

export default App;
