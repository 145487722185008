import React from 'react';

import search_icon from '../../assets/icons/search.png';
import close_icon from '../../assets/icons/close.png';

const NewCryptoAsset = (props) => {

    if (props.show_new_asset === true) {
      return (
        <div className="new-crypto-asset" id="new-crypto-asset">
          <img className="close-icon" src={close_icon} alt="" onClick={props.new_asset_close}></img>
          <span className="title">Search for asset</span>
          <input id="new-asset-ticker" type="text" placeholder="Search for Ticker (e.g. BTC)"></input>
          <div id="new-asset-submit" onClick={props.new_asset_submit}>
            <img className="search" alt="" src={search_icon}></img>
          </div>
        </div>
      )
    } else {
      return null;
    }

};

export default NewCryptoAsset;
