import React from 'react';

const ModalBackground = (props) => {

    if (props.show_modal_background === true) {
      return (
        <div className="modal-background"></div>
      )
    } else {
      return null;
    }

};

export default ModalBackground;
