import React from 'react';

const summary = (props) => {

    if (props.authenticated === true) {
      return (
        <div className="navbar">
          <div className="logo">
            <span className ="label">TokenTrack</span>
          </div>
          <div className="element">
            <span className ="link active">My Portfolio</span>
          </div>
          <div className="element">
            <span className ="link" onClick={props.show_settings}>My Settings</span>
          </div>
          <div className="logout" onClick={props.logout}>
            Log Out
          </div>
        </div>
      )
    } else {
      return (
        <div className="navbar">
          <div className="logo">
            <span className ="label">TokenTrack</span>
          </div>
        </div>
      )
    }
};

export default summary;
