import React from 'react';

import tick_icon from '../../assets/icons/tick.png';
import screenshot from '../../assets/images/tokentracker_screenshot.png';

const Auth = (props) => {

    if (props.authenticated === false) {
      let login_element = null
      if (props.show_sign_in === true) {
        login_element = (
          <div className="auth">

            <h2 className="title">Log In</h2>

            <input type="email" placeholder="email" id="login-email"></input>
            <input type="password" placeholder="password" id="login-password"></input>

            {props.show_login_error === true ?
              <div className="error">
                Incorrect login details
              </div> : null
            }

            <div className="submit-button" onClick={props.login_submit}>
              <img className="submit-icon" alt="" src={tick_icon}></img>
            </div>
            <span className="switch-mode" onClick={props.switch_auth_view}>Don't have an account? Sign up</span>
          </div>
        )
      } else {
        login_element = (
          <div className="auth">

            <h2 className="title">Sign up</h2>

            <input type="email" placeholder="email" id="sign-up-email"></input>
            <input type="password" placeholder="password" id="sign-up-password"></input>
            <input type="password" placeholder="verify password" id="sign-up-password-2"></input>

            {props.show_login_error === true ?
              <div class="error">
                Invalid details
              </div> : null
            }

            <div className="submit-button" onClick={props.sign_up_submit}>
              <img className="submit-icon" alt="" src={tick_icon}></img>
            </div>
            <span className="switch-mode" onClick={props.switch_auth_view}>Already have an account? Log in</span>
          </div>
        )
      }

      const info_element = (
        <div className="info-element">
          <h1>A simple, beautiful, cryptocurrency portfolio tracker</h1>
          <h2>Log in or sign up to get started</h2>
          <img className="screenshot" alt="" src={screenshot}></img>
        </div>
      )

      return (
        <div className="auth-container">
          {info_element}
          {login_element}
        </div>
      )


    } else {
      return null;
    }

};

export default Auth;
