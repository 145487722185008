import React from 'react';

import close_icon from '../../assets/icons/close.png';
import stage_1_img from '../../assets/images/onboarding/stage_1.jpg';
import stage_2_img from '../../assets/images/onboarding/stage_2.png';
import stage_3_img from '../../assets/images/onboarding/stage_3.png';
import stage_4_img from '../../assets/images/onboarding/stage_4.png';
import stage_5_img from '../../assets/images/onboarding/stage_5.png';
import stage_6_img from '../../assets/images/onboarding/stage_6.png';

const Onboarding = (props) => {

    let buttonHolder = (
      <div className = "button-holder">
        <div className = "button next" onClick={props.next_click}>
          Next
        </div>
        <div className = "button back" onClick={props.back_click}>
          Back
        </div>
      </div>
    )



    const stage_1 = (
      <div className="content">
        <div className = "text-block">
          <h2>Simple Crypto Tool</h2>
          <p>
            TokenTracker is minimalist web application which allows you to track your cryptocurrency portfolio.
          </p>
          <p>
            Keep tabs on both the value of your portfolio as well as basic performance metrics.
          </p>
        </div>
        <div className = "image-block">
          <img className="image-1" src={stage_1_img} alt=""></img>
        </div>
      </div>
    )

    const stage_2 = (
      <div className="content">
        <div className = "text-block">
          <h2>1. Configure your settings</h2>
          <p>
            The app needs the date of your first crypto investment to perform a basic annualized return calculation.
          </p>
          <p>
            Here you can also choose the local currency your portfolio will be valued in.
          </p>
        </div>
        <div className = "image-block">
          <img className="image-1" src={stage_2_img} alt=""></img>
        </div>
      </div>
    )

    const stage_3 = (
      <div className="content">
        <div className = "text-block">
          <h2>2. Add Assets to Track</h2>
          <p>
            You can add and track any Cryptocurrency listed on CoinMarketCap. Just make sure the ticker symbol is accurate when searching.
          </p>
          <p>
            <strong>Example:</strong> Do you hold Bitcoin? Add your Bitcoin holdings by searching "BTC" in the new asset card. Add each coin seperately.
          </p>
        </div>
        <div className = "image-block">
          <img className="image-1" src={stage_3_img} alt=""></img>
        </div>
      </div>
    )

    const stage_4 = (
      <div className="content">
        <div className = "text-block">
          <h2>3. Edit Crypto Balances</h2>
          <p>
            Click your balance to edit. You can only edit the balance of your crypto asset in crypto units (BTC, ETH, etc).
          </p>
          <p>
            <strong>Example: </strong>If you buy more Bitcoin you must update the BTC value not the £ value. This allows the app to automatically value your portfolio in your local currency.
          </p>
        </div>
        <div className = "image-block">
          <img className="image-1" src={stage_4_img} alt=""></img>
        </div>
      </div>
    )

    const stage_5 = (
      <div className="content">
        <div className = "text-block">
          <h2>4. Track Your Spend</h2>
          <p>
            The app has a simple (and, admittedly, limited) mechanism to track investment performance.
          </p>
          <p>
            Click on the "Total Invested" value to edit how much of your local currency you have invested throughout your crypto investing career.
          </p>
        </div>
        <div className = "image-block">
          <img className="image-1" src={stage_5_img} alt=""></img>
        </div>
      </div>
    )

    const stage_6 = (
      <div className="content">
        <div className = "text-block">
          <h2>5. Analyze Performance</h2>
          <p>
            Track the Portfolio Value, Total Spend, Total Return, and Annualised Return.
          </p>
          <p>
            <strong>Total Return</strong> = 100 * (Portfolio Value - Total Invested) / (Total Invested)
          </p>
          <p>
            <strong>Annualised Return</strong> = 100 * (Total Return) / (Number of years since first crypto investment)
          </p>
        </div>
        <div className = "image-block">
          <img className="image-1" src={stage_6_img} alt=""></img>
        </div>
      </div>
    )

    let content = null
    if (props.stage === 1) {
      content = stage_1;
      buttonHolder = (
        <div className = "button-holder">
          <div className = "button next" onClick={props.next_click}>
            Next
          </div>
        </div>
      )
    } else if (props.stage === 2) {
      content = stage_2;
    } else if (props.stage === 3) {
      content = stage_3;
    } else if (props.stage === 4) {
      content = stage_4;
    } else if (props.stage === 5) {
      content = stage_5;
    } else if (props.stage === 6) {
      content = stage_6;
      buttonHolder = (
        <div className = "button-holder">
          <div className = "button next" onClick={props.close_onboarding_modal}>
            Finish
          </div>
          <div className = "button back" onClick={props.back_click}>
            Back
          </div>
        </div>
      );

    }


    if (props.show_onboarding === true) {
      return (
        <div className="onboarding-modal">
          <img className="close-icon" src={close_icon} alt="" onClick={props.close_onboarding_modal}></img>
          <h1>Welcome to TokenTracker</h1>
          {content}
          {buttonHolder}
        </div>
      )
    } else {
      return null
    }




};

export default Onboarding;
